import React from 'react';
import './Phasesix.css'; // Import the CSS file for styling

const Phasesix = () => {
  return (
    <div className="phasesix-container">
      <h1 className="phasesix-heading">Now there is a better way…</h1>
      <h3 style={{ fontSize: '1.5rem' }}>Watch Our Detailed Explanation</h3>
      <div className="phasesix-video-container">
        <iframe 
          width="100%" 
          height="500px" 
          src="https://www.youtube.com/embed/RiSUWkxBtXE?si=iMBEzFN6PGjht3pV" 
          title="YouTube video player" 
          frameBorder="0" 
         
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" 
          allowFullScreen>
        </iframe>
      </div>
    </div>
  );
};

export default Phasesix;
