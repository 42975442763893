import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/gps-logo-white.025ee053.png';
import { FaWhatsapp, FaInstagram, FaLinkedin } from 'react-icons/fa';
import axios from 'axios'; // Import Axios for HTTP requests

const Foot = () => {
  const [email, setEmail] = useState(''); // State for email input
  const [isSubmitted, setIsSubmitted] = useState(false); // To handle submission status

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formActionUrl = 'https://script.google.com/macros/s/AKfycbxIkTr-peXrKgGatazVHQqB0rwjhmIfEULjyoWvdOOVk33kVeVijqEhlPwBwyGF8HVb6Q/exec'; // Replace with your actual URL

    const data = new URLSearchParams();
    data.append('EMAIL', email); // Email field
    data.append('MESSAGE', 'NEWS_LETTER'); // Hidden message for the newsletter

    axios.post(formActionUrl, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {
      console.log('Submitted:', response);
      setEmail(''); // Reset email field after submission
      setIsSubmitted(true); // Set the form to 'submitted' state
      alert('You have successfully signed up!'); // Optional success alert
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Something went wrong, please try again.'); // Optional error alert
    });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        
        {/* Left Section */}
        <div className="footer-left">
          <img src={logo} alt="Global Prostate Solutions" className="footer-logo" />
          <p className="tagline">Precision. Innovation. Reliability.</p>
          <div className="social-links">
            <a href="https://api.whatsapp.com/send?phone=447389806075" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/globalprostatesolution?igsh=eDJwbnJ5YWh1YzR4" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/global-prostate-solutions-ltd/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin />
            </a>
          </div>
          <div className="footer-legal-links">
            <Link to="/privacy" className="footer-link">Privacy Policy</Link>
            <Link to="/privacy" className="footer-link">Terms of Service</Link>
          </div>
          <p className="footer-description">
            Global Prostate Solutions, established in 2012, is an innovative UK-based company specializing in comprehensive prostate biopsy solutions. With an experienced 24/7 team and a commitment to excellent customer service, we provide cutting-edge diagnostic services for accurate and timely prostate cancer detection.
          </p>
        </div>

        {/* Middle Section */}
        <div className="footer-middle">
          <h3 className="footer-title">Links</h3>
          <ul className="footer-links-list">
            <li><span className="link-icon">—</span><Link to="/about-us" className="footer-link">About Us</Link></li>
            {/* <li><span className="link-icon">—</span><Link to="/ForProduct" className="footer-link">Our Products</Link></li> */}
            <li><span className="link-icon">—</span><Link to="/ForPatients" className="footer-link">For Patients</Link></li>
            <li><span className="link-icon">—</span><Link to="/ForProviders" className="footer-link">TP Biopsy Box</Link></li>
            <li><span className="link-icon">—</span><Link to="/Tower" className="footer-link">Global Innovations</Link></li>
          </ul>
        </div>

        {/* Right Section - Newsletter Signup */}
        <div className="footer-right">
          <div className="newsletter-section">
            <h3 className="newsletter-title">Sign up to our newsletter</h3>
            <p className="newsletter-text">Receive the latest news, exclusive discounts & offers straight to your inbox!</p>
            <form onSubmit={handleSubmit} className="newsletter-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                className="newsletter-input"
                required
              />
              <button type="submit" className="newsletter-submit">
                {isSubmitted ? 'Subscribed' : 'Submit'}
              </button>
            </form>
          </div>

          {/* Contact Us Section */}
          <div className="contact-info">
            <p><strong>Contact Us</strong></p>
            <p>+44 (0) 124 539 2110</p>
            <a href="mailto:query.globalps@gmail.com" style={{ textDecoration: 'none', color: 'white' }}>query.globalps@gmail.com</a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="footer-bottom">
        <p>© 2024 Global Prostate Solutions</p>
        <p>Designed & developed by <a href="https://mark4bi.com/" target="_blank" rel="noopener noreferrer">Mark4Bi</a></p>
      </div>
    </footer>
  );
};

export default Foot;
