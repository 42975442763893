import React from 'react';
import './L4.css'; // Keeping the same CSS import

function L4() {
    return (
        <>
           <h1 className="m3-header" style={{fontSize :'3rem'}}>Free hand mechanism | Hand’s free mechanism</h1>
           <p className="l4-description">
               The Tower is a precision medical device designed to assist in accurate transperineal prostate biopsies. It provides urologists with a hands-free needle guidance system, allowing for improved control and targeting during procedures. The guide is engineered to be compatible with ultrasound systems, offering real-time visualization for precise needle placement.

               This device is particularly beneficial in reducing patient discomfort and minimizing the risk of infection since it supports a sterile, controlled environment. Its ergonomic design ensures ease of use, while its adaptability to various needle sizes makes it versatile for different biopsy needs. Additionally, the Tower's free-hand mechanism enhances accuracy, making it a preferred choice for both diagnostic and therapeutic applications in urology.
           </p>
            <div className="l4-container">
                <div className="phasesix-video-container">
                    <iframe 
                        width="100%" 
                        height="500px" 
                        src="https://www.youtube.com/embed/v-DrRVcC97Q?si=RYko_TSwz-3eT-pi" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </>
    );
}

export default L4;
