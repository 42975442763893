import React, { useState } from 'react';
import axios from 'axios'; // Import Axios for HTTP requests
import './Popup.css'; // For styling the popup

export default function BookADemoPopup({ onClose }) {
  const [name, setName] = useState(''); // Only one field for name
  const [email, setEmail] = useState(''); // Field for email
  const [message, setMessage] = useState(''); // Field for message

  const handleSubmit = (e) => {
    e.preventDefault();

    const formActionUrl = 'https://script.google.com/macros/s/AKfycbxIkTr-peXrKgGatazVHQqB0rwjhmIfEULjyoWvdOOVk33kVeVijqEhlPwBwyGF8HVb6Q/exec'; // Replace with your actual URL

    const data = new URLSearchParams();
    data.append('NAME', name); // Key for name
    data.append('EMAIL', email); // Key for email
    data.append('MESSAGE', message); // Key for message

    axios.post(formActionUrl, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {
      console.log('Submitted:', response);
      // Reset form fields
      setName('');
      setEmail('');
      setMessage('');
      onClose(); // Close the popup after successful submission
      alert('Message sent successfully!'); // Optional success alert
    })
    .catch((error) => {
      console.error('Error submitting form:', error);
      alert('Something went wrong, please try again.'); // Optional error alert
    });
  };

  return (
    <div className="bookademopopup-overlay">
      <div className="bookademopopup-container">
        <button className="bookademopopup-close-button" onClick={onClose} aria-label="Close Popup">
          &times; {/* "X" character for the close button */}
        </button>
        <h2 className="bookademopopup-header">Contact Sales</h2>
        <form onSubmit={handleSubmit}>
          <div className="bookademopopup-form-group">
            <input
              type="text"
              id="name"
              value={name}
              placeholder='Your Name'
              onChange={(e) => setName(e.target.value)}
              required
              style={{ width: '90%' }} // Inline style for width
            />
          </div>
          <div className="bookademopopup-form-group">
            <input
              type="email"
              id="email"
              value={email}
              placeholder='Email Address'
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '90%' }} // Inline style for width
            />
          </div>
          <div className="bookademopopup-form-group">
            <textarea
              id="message"
              value={message}
              placeholder='Your Message'
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ width: '90%', height: '100px' }} // Inline style for width and height
            />
          </div>
          <div className="bookademopopup-button-container" style={{ marginTop: '20px' }}>
            <button type="submit" className="bookademopopup-submit-button">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}