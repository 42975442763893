import React, { useState } from 'react';

const Rentals = () => {
    const [hospitalName, setHospitalName] = useState('');
    const [surgeonName, setSurgeonName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
    const [numPatients, setNumPatients] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [services, setServices] = useState('Stepper-grid guided transperineal biopsy');
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('hospital_name', hospitalName);
        formData.append('surgeon_name', surgeonName);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('purchase_order_number', purchaseOrderNumber);
        formData.append('num_patients', numPatients);
        formData.append('date', date);
        formData.append('time', time);
        formData.append('services', services);

        try {
            const response = await fetch('http://localhost/Enquiry/process_booking.php', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                alert('Booking processed successfully!');
            } else {
                alert(`Error: ${result.error || 'Something went wrong'}`);
            }
        } catch (error) {
            alert('Network error, please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Hospital Name</label>
                <input 
                    type="text" 
                    value={hospitalName} 
                    onChange={(e) => setHospitalName(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Surgeon Name</label>
                <input 
                    type="text" 
                    value={surgeonName} 
                    onChange={(e) => setSurgeonName(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Phone</label>
                <input 
                    type="number" 
                    value={phone} 
                    onChange={(e) => setPhone(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Email</label>
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Purchase Order Number</label>
                <input 
                    type="text" 
                    value={purchaseOrderNumber} 
                    onChange={(e) => setPurchaseOrderNumber(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Number of Patients</label>
                <input 
                    type="number" 
                    value={numPatients} 
                    onChange={(e) => setNumPatients(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Date</label>
                <input 
                    type="date" 
                    value={date} 
                    onChange={(e) => setDate(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Time</label>
                <input 
                    type="time" 
                    value={time} 
                    onChange={(e) => setTime(e.target.value)} 
                    required 
                />
            </div>
            <div>
                <label>Services</label>
                <select 
                    value={services} 
                    onChange={(e) => setServices(e.target.value)} 
                    required
                >
                    <option value="Stepper-grid guided transperineal biopsy">Stepper-grid guided transperineal biopsy</option>
                    <option value="Free hand biopsy">Free hand biopsy</option>
                </select>
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default Rentals;
