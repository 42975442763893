
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo1.png'; // Use a single logo
import Popup from '../components/Popup'; 

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    setDropdown(false); // Close the dropdown when opening the mobile menu
  };

  // Toggle dropdown menu visibility
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent the event from propagating up to the mobile menu
    setDropdown(!dropdown);
  };

  // Toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  // Close the dropdown if clicked outside
  const closeDropdown = () => {
    setDropdown(false);
  };

  // Close the mobile menu if clicked outside
  const handleClickOutside = (e) => {
    // Close mobile menu if clicked outside
    if (isMobile && !e.target.closest('.NavbarOne')) {
      setIsMobile(false);
      setDropdown(false); // Close the dropdown if mobile menu is closed
    } else if (dropdown && !e.target.closest('.NavbarOne-dropdown')) {
      setDropdown(false); // Close dropdown if clicked outside dropdown
    }
  };

  // Attach event listener to detect clicks outside
  useEffect(() => {
    if (isMobile || dropdown) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile, dropdown]);

  return (
    <nav className="NavbarOne">
      <div className="NavbarOne-left">
        <Link to="/">
          <img src={logo} alt="Logo" className="NavbarOne-logo" /> {/* Single logo */}
        </Link>
        <ul className={isMobile ? 'NavbarOne-nav-links mobile active' : 'NavbarOne-nav-links'}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/ForPatients">For Patients</Link></li>
          <li className="NavbarOne-dropdown">
            <a href="#!" onClick={toggleDropdown}>Product &#9660;</a>
            {dropdown && (
              <div className="NavbarOne-dropdown-menu">
                <Link to="/Sales">Sales</Link>
                <Link to="/ProBook">Services</Link>
              </div>
            )}
          </li>
          <li><Link to="/ForProviders">Biopsy Box</Link></li>
          <li><Link to="/Tower">Innovations</Link></li>

          {/* Add Buy Now link inside the mobile menu */}
          {isMobile && (
            <>
              <li style={{ textDecoration: 'none' }}>
                <Link to="#" onClick={togglePopup} className="contact-sales-link">Contact Sales</Link>
              </li>
              <li style={{ marginTop: '20px' }}>
                <Link to="/buy-now" className="buy-now-button">Buy Now</Link>
              </li>
            </>
          )}
        </ul>
      </div>

      {/* Right end items: Buy Now (Desktop only) */}
      {!isMobile && (
        <div className="NavbarOne-right">
          <ul>
            <li style={{ marginTop: '18px' }}>
              <Link to="#" onClick={togglePopup} className="contact-sales-link">Contact Sales</Link>
            </li>
            <li>
              <Link to="https://genkot.com/Ticketing/" className="buy-now-button">Book Now</Link>
            </li>
          </ul>
        </div>
      )}

      {/* Mobile menu icon */}
      <div className="NavbarOne-menu-icon" onClick={toggleMobileMenu}>
        <i style={{ marginRight: '20px', color: 'black' }} className={isMobile ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>

      {/* Popup */}
      {isPopupVisible && <Popup onClose={togglePopup} />} {/* Render Popup */}
    </nav>
  );
}

export default Navbar;
