
import React, { useState } from 'react';
import './Carousel.css';
import logo1 from '../images/j2.jpg'; 
import logo2 from '../Blogs/b2.jpg'; 
import logo3 from '../Blogs/newb1.jpg';
import logo4 from '../Blogs/newb4.jpg';
import { Link } from 'react-router-dom';

function Carousel() {
 const blogItems = [
   {
     id: 1,
     image: logo1,
     category: "Guides",
     title: "Key Solutions for Managing Prostate Health",
     description: "Maintaining prostate health is essential for men, particularly as they age. With prostate issues like benign prostatic hyperplasia (BPH), prostatitis, and even...",
     linkTo: "/blog"
   },
   {
     id: 2,
     image: logo2,
     category: "Guides",
     title: "Transrectal vs Transperineal Method  ",
     description: "\n\nProstate biopsies are a critical diagnostic tool in the detection of prostate cancer, a disease that affects millions of men worldwide...",
     linkTo: "/blog1"
     
   },
   {
     id: 3,
     image: logo3,
     category: "Expertise",
     title: "Biopsy Box Eases NHS Cost Pressure",
     description: "Healthcare costs are a significant burden on medical systems worldwide, and the NHS is no exception. Rising expenses in prostate cancer diagnostics...",
     linkTo: "/blog2"
   }
 ];

 return (
   <div className="blog-section">
     <h1 className="section-title">Latest Blogs & Insights</h1>
     <div className="blog-grid">
       {blogItems.map((item) => (
         <div key={item.id} className="blog-card">
           <Link to={item.linkTo} className="blog-link">
             <div className="blog-image">
               <img src={item.image} alt={item.title} />
             </div>
             <div className="category-tag">{item.category}</div>
             <div className="blog-content">
               <h2 className="blog-title">{item.title}</h2>
               <p className="blog-description">{item.description}</p>
             </div>
           </Link>
         </div>
       ))}
     </div>
     {/* <button className="view-all-button">View all insights</button> */}
   </div>
 );
}

export default Carousel;
