import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import Popup from './PopUp2'; // Adjust the path as needed

function LandingPage() {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div className="landing-page">
      {/* Outer wrapper around PC images */}
      <div className="image-box-container pc-images">
        <Link to="/ForProviders" className="image-box box-pc-1">
          <div className="box-content">
            <i className="fas fa-cart-plus"></i>
            <p>TP</p>
            <p style={{ marginTop: '-10px' }}>Biopsy Box</p>


            <button className="box-button">Get A Free Demo</button> {/* Button Added */}
          </div>
        </Link>
        <Link to="/nhs" className="image-box box-pc-2">
          <div className="box-content">
            <i className="fas fa-clock"></i>
            <p>NHS</p>
            <p style={{ marginTop: '-10px' }}>Backlogs</p>
            <button className="box-button">Learn More</button> {/* Button Added */}
          </div>
        </Link>
        <a href="https://enquiry.globalprostatesolutions.com/rental.php" className="image-box box-pc-3" target="_blank" rel="noopener noreferrer">
          <div className="box-content">
            <i className="fas fa-phone-alt"></i>
            <p>Rental</p>
            <p style={{ marginTop: '-10px' }}>Services</p>
            <button className="box-button">Learn More</button> {/* Button Added */}
          </div>
        </a>

        {/* New Image Box for PC */}
        <Link to="/Tower" className="image-box box-pc-4">
          <div className="box-content">
            <i className="fas fa-handshake"></i>
            <p>Global </p>
            <p style={{ marginTop: '-10px' }}>Innovations</p>
            <button className="box-button">Learn More</button> {/* Button Added */}
          </div>
        </Link>
      </div>

      {/* Image boxes for Mobile */}
      <div className="image-box-wrapper"></div>
      <div className="image-box-container mobile-images">
        <Link to="/ForProviders" className="image-box box-mobile-1">
          <div className="box-content">
            <i className="fas fa-cart-plus"></i>
            <p>TP</p>
            <p>Biopsy Box</p>
            <button className="box-button" style={{ fontSize: '10px' }}>Get A Free Demo</button> {/* Button Added */}
          </div>
        </Link>
        <Link to="/nhs" className="image-box box-mobile-2">
          <div className="box-content">
            <i className="fas fa-clock"></i>
            <p>NHS</p>
            <p>Backlogs</p>
            <button className="box-button" style={{ fontSize: '10px' }}>Learn More</button> {/* Button Added */}
          </div>
        </Link>
        <a href="https://enquiry.globalprostatesolutions.com/rental.php" className="image-box box-mobile-3" target="_blank" rel="noopener noreferrer">
          <div className="box-content">
            <i className="fas fa-phone-alt"></i>
            <p>Rental</p>
            <p>Services</p>
            <button className="box-button" style={{ fontSize: '10px' }}>Learn More</button> {/* Button Added */}
          </div>
        </a>

        {/* New Image Box for Mobile */}
        <Link to="/Tower" className="image-box box-mobile-4">
          <div className="box-content">
            <i className="fas fa-handshake"></i>
            <p>Global </p>
            <p>Innovations</p>
            <button className="box-button" style={{ fontSize: '10px' }}>Learn More</button> {/* Button Added */}
          </div>
        </Link>
      </div>

      {/* Popup for Booking a Demo */}
      {isPopupVisible && <Popup onClose={togglePopup} />}
    </div>
  );
}

export default LandingPage;
