import React from 'react';
import './aboutlan.css'; 
import sampleImage from './neww.jpg';


export default function Aboutlan() {
  return (
    <>
            <h2 className="bottom-header-phasethree" style={{textAlign:'center' , marginTop:'70px'}}>Efficient Patient Backlog Clearing Service for Hospitals</h2>
    <div className="about-container">
      
      <div className="left-column">

        <p>

        If your hospital is experiencing a backlog of patients, Global Prostate Solutions is here to help. We offer a comprehensive, hassle-free solution to streamline your workflow and reduce patient wait times, ensuring that every patient receives timely, high-quality care. 
        <ul className="description-list" style={{color:'#555' , marginBottom:'-50px'}}>
            <li className="description-item">Minimum Requirement: 5 Patients / Day  .</li>
            <li className="description-item">Affordable Package Rate: £250 Per Patient</li>

          </ul>
        </p>
      </div>
      <div className="right-column">
      <img src={sampleImage} alt="Description" />

      </div>
    </div>
    </>
  );
}
