// ImgTexOne.js
import React from 'react';
import './ImgTexOne.css'; // Import the updated CSS file

// Importing the images directly from the local folder
import topView from '../product_images/echographe-flex-focus-500-1-large.png';
import sideView from '../finalImage/sure2.JPG';
import bottomView from '../product_images/echographe-flex-focus-500-1-large.png';
import leftView from '../product_images/echographe-flex-focus-500-1-large.png';

const ImgTexSeven = () => {
  // State to hold the currently selected image
  const [mainImage, setMainImage] = React.useState(topView); // Default to top view

  // Array of image sources
  const images = {
    top: topView,
    side: sideView,
    bottom: bottomView,
    left: leftView,
  };

  return (
    <div className="imgtexone_container">
      <div className="imgtexone_leftColumn">
        <div className="imgtexone_imageWrapper">
          {/* Primary Image */}
          <div>
            <img src={mainImage} alt="Product" className="imgtexone_mainImage" />
          </div>

          {/* Thumbnails */}
          <div className="imgtexone_thumbnailContainer">
            <img 
              src={images.top} 
              alt="Top View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.top)} 
            />
            <img 
              src={images.side} 
              alt="Side View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.side)} 
            />
            <img 
              src={images.bottom} 
              alt="Bottom View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.bottom)} 
            />
            <img 
              src={images.left} 
              alt="Left View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.left)} 
            />
          </div>
        </div>
      </div>
      <div className="imgtexone_rightColumn">
        <h2 className="imgtexone_heading">Our Product Overview</h2>
        <p className="imgtexone_description">
          BK with transperineal probe, 6 months of consumables free, refurbished with a 3-year warranty, and offered at the best price.
        </p>
      </div>
    </div>
  );
};

export default ImgTexSeven;
