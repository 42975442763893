// ImgTexThree.js
import React from 'react';
import './ImgTexOne.css'; // Import the updated CSS file

// Import images from your local folder
import topViewImage from '../finalImage/gun1.jpg';
import sideViewImage from '../finalImage/gun2.jpg';
import bottomViewImage from '../finalImage/gun3.jpg';


const ImgTexThree = () => {
  // State to hold the currently selected image
  const [mainImage, setMainImage] = React.useState(topViewImage);

  // Array of image sources
  const images = {
    top: topViewImage,
    side: sideViewImage,
    bottom: bottomViewImage,
 
  };

  return (
    <div className="imgtexone_container">
      <div className="imgtexone_leftColumn">
        <div className="imgtexone_imageWrapper">
          {/* Primary Image */}
          <div>
            <img src={mainImage} alt="Product" className="imgtexone_mainImage" />
          </div>

          {/* Thumbnails */}
          <div className="imgtexone_thumbnailContainer">
            <img 
              src={images.top} 
              alt="Top View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.top)} 
            />
            <img 
              src={images.side} 
              alt="Side View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.side)} 
            />
            <img 
              src={images.bottom} 
              alt="Bottom View" 
              className="imgtexone_thumbnail" 
              onClick={() => setMainImage(images.bottom)} 
            />
           
          </div>
        </div>
      </div>
      <div className="imgtexone_rightColumn">
        <h2 className="imgtexone_heading">Our Product Overview</h2>
        <p className="imgtexone_description">
        The Biopsy Gun is engineered for quick, accurate, and consistent tissue sampling, ensuring precise extraction with minimal damage, effortless one-handed operation, and reduced procedure time for enhanced diagnostic accuracy.
        </p>
      </div>
    </div>
  );
};

export default ImgTexThree;
